@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin";
@import '~assets/styles/variables';

.ag-theme-ims {
  @include ag-theme-material((
    header-background-color: rgba(0, 0, 0, 0)
  ));

  .ag-root {
    background-color: rgba(0, 0, 0, 0);
  }

  .ag-root-wrapper {
    background-color: rgba(0, 0, 0, 0);
  }

  .ag-row-even {
    background-color: #F7F9FA;
  }

  .ag-row-odd {
    background-color:rgba(0, 0, 0, 0);
  }


  .ag-header .ag-pinned-left-header,
  .ag-body-viewport .ag-pinned-left-cols-container {
    position: relative;
    z-index: 1;
    -webkit-box-shadow: 9px 1px 15px -4px rgba(0, 0, 0, 0.1);
    box-shadow: 9px 1px 15px -4px rgba(0, 0, 0, 0.1);
    border-left: 0px solid #ccc;
  }


  .ag-header .ag-pinned-right-header,
  .ag-body-viewport .ag-pinned-right-cols-container {
    position: relative;
    z-index: 1;
    -webkit-box-shadow: -9px 1px 15px -4px rgba(0, 0, 0, 0.1);
    box-shadow: -9px 1px 15px -4px rgba(0, 0, 0, 0.1);
    border-right: 0px solid #dcdcdc;
  }

  .ag-header,
  .ag-body-viewport {
    z-index: 0;
  }

  .ag-cell {
    color: $primary-grey;
    font-weight: 400;
    font-size: 12px;
  }

  .ag-filter-select {
    .no-filter-select & {
      display: none
    }
  }

  // To have the sort icon on the left of the header labels: reverse the flow:
  .ag-header-cell-label {
    flex-direction: row-reverse;
    justify-content: left;
    color: $primary-grey;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    .ag-header-icon {
      margin-left: 0 !important;
      margin-right: 8px;
    }
  }

  // Add custom headerTooltip.

  .ag-tooltip{
    display:inline-block;
    color: white;
    background-color: #1F2D2F;
    padding: 5px 12px;
    margin-top:-8px !important;
    top: 5px !important;
    align-items: center;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    font-weight: normal;
    font-style: normal;
    line-height: 1;
    z-index: 1000;
  }

  .ag-checkbox-input-wrapper.ag-checked::after {
    color: #ED0000 !important;
  }
}
