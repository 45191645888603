@import '~normalize.css';
@import 'material-icons/iconfont/material-icons.css';
@import '~@totalenergiescode/design-system/dist/css/totalenergies-design-system.css';
@import './assets/styles/styles';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');
@import 'ag-grid-community/dist/styles/ag-grid.css';
@import './ag-theme-ims';

@import '~@totalenergiescode/design-system/dist/css/angular-ng-select/ng-select.css';
@import '~@totalenergiescode/design-system/dist/css/custom-components/upload-files.css';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
html, body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.page-body-wrapper {
  background-color: #F7F9FA;

  .content-wrapper {
   // padding:1rem;
    background: #F7F9FA;
    width: 100%;
  }

}
.sidebar-wrapper{
  background-color: white;
}
.title-page-home {
  position: static;
  width: 366px;
  height: 42px;
  left: 0px;
  top: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 42px;
  text-align: center;
  color: #374649;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}

// For Accordion
.title-accordion{
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  padding: 1.5rem;
}

.accordion-item {
  background-color: white;
}

.tooltip-inner {
  text-align: left;
  width: fit-content;
  max-width: 100%;
}

.ng-select.ng-select-single .ng-select-container {
  height: 36px;
  width: 100%;
}

@media (max-width: 1600px){
  .form-control{
    font-size: 12px !important;
  }
  .accordion-body{
    font-size: 12px !important;
  }
 }
